import React, { useEffect, useState, VFC } from 'react';
import { hesselApiTypes } from '../../../lib/api/models/hessel-api';
import { FinancialCalculationsModalSettings } from '../../../lib/api/models/umbraco/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { formatPrice } from '../../../utils/helpers';
import { Spinner } from '../../shared/spinner';
import { KeyValueTableRow } from '../../shared/tables/key-value-table/row';
import { Description, SummarySection, SummaryHeader, CalculationTable, ErrorText, SpinnerWrapper } from './santander-financing-summary.styled';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { usePriceByOwnership } from '../hooks/use-price-by-ownership';
import { ProductDetailsPage as UmbracoProductDetailsPage } from '../../../lib/api/models/umbraco';
import { Abbreviations } from '../../../constants/units-and-abbreviations';

type SantanderFinancingSummaryProps = {
    product: hesselViewModels.ProductDetails;
    modalSettings: FinancialCalculationsModalSettings;
    page: UmbracoProductDetailsPage;
};

const down_payment_values = {
    default: 20,
    min: 20,
    max: 40,
    interval: 10,
};

const duration_values = {
    default: 84,
    min: 24,
    max: 96,
    interval: 12,
};

export const SantanderFinancingSummary: VFC<SantanderFinancingSummaryProps> = ({ product, modalSettings, page }) => {
    const { financialPurchaseDownPaymentPercentage, computedCashPrice } = ProductDetailsStore.useStoreState((state) => state);

    const duration = product.purchaseTypes.financing.durationMonths ?? duration_values.default;
    const downPaymentPercentage = financialPurchaseDownPaymentPercentage ?? down_payment_values.default;
    const [santanderFinancingToShow, setSantanderFinancingToShow] = useState<hesselApiTypes.CarFinancing | undefined>(undefined);

    const {
        santanderFinancing,
        santanderError: error,
        santanderIsLoading: isLoading,
        santanderIsValidating: isValidating,
    } = usePriceByOwnership(page);

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (!santanderFinancing) {
            return;
        }
        if (santanderFinancing && !error) {
            setSantanderFinancingToShow(santanderFinancing);
            return;
        }
        setSantanderFinancingToShow(undefined);
    }, [santanderFinancing, error]);

    useEffect(() => {
        setShowSpinner(false);
        if (isLoading || isValidating) {
            const timeout = setTimeout(() => setShowSpinner(true), 1000);
            return () => clearTimeout(timeout);
        }
    }, [computedCashPrice, duration, downPaymentPercentage, santanderFinancingToShow, isLoading, isValidating]);

    return (
        <div>
            {error === 'Data Fetch Failed' ? (
                <ErrorText>Vi kan desværre ikke beregne finansieringspriser i øjeblikket. Prøv venligst igen senere.</ErrorText>
            ) : null}

            {!!santanderFinancingToShow && !showSpinner && !error ? (
                <>
                    <SummarySection>
                        <SummaryHeader>Oversigt</SummaryHeader>
                        <Description>{modalSettings.description}</Description>
                        <CalculationTable>
                            <KeyValueTableRow
                                label="Månedlig ydelse"
                                value={`${formatPrice(santanderFinancingToShow?.paymentPerMonth ?? 0)} ${Abbreviations.KR_SLASH_MD}`}
                            />
                            <KeyValueTableRow
                                label={modalSettings.downPaymentLabel}
                                value={`${formatPrice(santanderFinancingToShow?.downPayment ?? 0)} ${Abbreviations.KR}`}
                            />
                            <KeyValueTableRow label={modalSettings.durationLabel} value={`${duration} ${Abbreviations.MDR}`} />
                            <KeyValueTableRow
                                label={modalSettings.variableDebtorInterestLabel}
                                value={`${formatPrice(santanderFinancingToShow?.nominalInterest ?? 0, 2)}%`}
                            />
                            <KeyValueTableRow label={modalSettings.aopLabel} value={`${santanderFinancingToShow?.aopBeforeTax}%`} />
                            <KeyValueTableRow
                                label={modalSettings.totalLoanCostsLabel}
                                value={`${formatPrice(santanderFinancingToShow?.totalLoanCosts ?? 0)} ${Abbreviations.KR}`}
                            />
                            <KeyValueTableRow
                                label={modalSettings.totalRepayLabel}
                                value={`${formatPrice(santanderFinancingToShow?.totalRepay ?? 0)} ${Abbreviations.KR}`}
                            />
                        </CalculationTable>
                    </SummarySection>
                </>
            ) : null}

            {showSpinner && !error ? (
                <SpinnerWrapper>
                    <Spinner size="medium" variant="light" />
                </SpinnerWrapper>
            ) : null}
        </div>
    );
};
