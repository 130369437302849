import React, { useEffect, useState, VFC } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { formatPrice } from '../../../utils/helpers';
import { Spinner } from '../../shared/spinner';
import { SteppedInputSlider } from '../../shared/stepped-input-slider/stepped-input-slider.component';
import {
    CalculatorSection,
    SliderContent,
    Label,
    LabelAndValue,
    ErrorText,
    SpinnerWrapper,
    CalculationsContainer,
} from './financial-calculations.styled';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { usePriceByOwnership } from '../hooks/use-price-by-ownership';
import { ProductDetailsPage as UmbracoProductDetailsPage } from '../../../lib/api/models/umbraco';

type FinancialCalculationsProps = {
    product: hesselViewModels.ProductDetails;
    page: UmbracoProductDetailsPage;
};

const down_payment_values = {
    default: 20,
    min: 20,
    max: 40,
    interval: 10,
};

const duration_values = {
    default: 84,
    min: 24,
    max: 96,
    interval: 12,
};

export const FinancialCalculations: VFC<FinancialCalculationsProps> = ({ product, page }) => {
    const { financialPurchaseDownPaymentPercentage, computedCashPrice } = ProductDetailsStore.useStoreState((state) => state);
    const { setFinancialPurchaseTypeDurationMonths, setFinancialPurchaseTypeDownPaymentPercentage } = ProductDetailsStore.useStoreActions(
        (actions) => actions
    );

    const duration = product.purchaseTypes.financing.durationMonths ?? duration_values.default;
    const downPaymentPercentage = financialPurchaseDownPaymentPercentage ?? down_payment_values.default;

    const { santanderError: error, santanderIsLoading: isLoading, santanderIsValidating: isValidating } = usePriceByOwnership(page);

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        setShowSpinner(false);
        if (isLoading || isValidating) {
            const timeout = setTimeout(() => setShowSpinner(true), 1000);
            return () => clearTimeout(timeout);
        }
    }, [computedCashPrice, duration, downPaymentPercentage, isLoading, isValidating]);

    return (
        <CalculationsContainer>
            {error === 'Data Fetch Failed' ? (
                <ErrorText>Vi kan desværre ikke beregne finansieringspriser i øjeblikket. Prøv venligst igen senere.</ErrorText>
            ) : null}

            <CalculatorSection>
                <SliderContent>
                    <LabelAndValue>
                        <Label>Løbetid</Label>
                        <Label bold>{duration} mdr.</Label>
                    </LabelAndValue>
                    <SteppedInputSlider
                        min={duration_values.min}
                        max={duration_values.max}
                        defaultValue={duration_values.default}
                        interval={duration_values.interval}
                        value={duration}
                        onValueChange={(e) => {
                            setFinancialPurchaseTypeDurationMonths({
                                durationMonths: e,
                            });
                        }}
                    />
                </SliderContent>

                <SliderContent>
                    <LabelAndValue>
                        <Label>Udbetaling</Label>
                        <Label bold>{formatPrice(Math.ceil(computedCashPrice * (downPaymentPercentage / 100)))} kr.</Label>
                    </LabelAndValue>
                    <SteppedInputSlider
                        min={down_payment_values.min}
                        max={down_payment_values.max}
                        defaultValue={down_payment_values.default}
                        interval={down_payment_values.interval}
                        value={downPaymentPercentage}
                        onValueChange={(e) => {
                            setFinancialPurchaseTypeDownPaymentPercentage({
                                downPaymentPercentage: e,
                            });
                        }}
                        unit="%"
                    />
                </SliderContent>
            </CalculatorSection>

            {showSpinner && !error ? (
                <SpinnerWrapper>
                    <Spinner size="medium" variant="light" />
                </SpinnerWrapper>
            ) : null}
        </CalculationsContainer>
    );
};
