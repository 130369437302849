import styled from 'styled-components';
import * as SliderPrimitive from '@radix-ui/react-slider';

export const BorderBox = styled.div``;

export const InputSliderWrapper = styled.div`
    position: relative;
`;

// Input slides
export const StyledSlider = styled(SliderPrimitive.Root)`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
    padding: 1em 0;
    margin: -1em 0;
`;

export const StyledTrack = styled(SliderPrimitive.Track)`
    background-color: ${({ theme }) => theme.palette.grey.medium};
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;

    &[data-orientation='horizontal'] {
        height: 5px;
    }
    &[data-orientation='vertical'] {
        width: 5px;
    }
`;

export const StyledRange = styled(SliderPrimitive.Range)`
    position: absolute;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 9999px;
    height: 100%;
`;

export const StyledThumb = styled(SliderPrimitive.Thumb)`
    all: unset;
    display: block;
    width: 12px;
    height: 12px;
    background-color: white;
    border: 4px solid ${({ theme }) => theme.palette.primary.main};

    border-radius: 10px;
    &:hover {
        background-color: white;
        cursor: grab;
    }
    &:focus {
        box-shadow: 0 2px 10px #000;
    }
`;

export const MinMaxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const StyledMinMaxText = styled.div`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;
