import styled from 'styled-components';

export const CalculationsContainer = styled.div`
    margin: 0 20px;
    padding: 24px;
    background-color: #f7f7f8;
    border-radius: 4px;
`;

export const CalculatorSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
`;

export const SliderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;
`;

export const LabelAndValue = styled.div`
    display: flex;
    gap: 4px;
`;

export const Label = styled.label<{ bold?: boolean }>`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: ${(props) => (props.bold ? 600 : 400)};
    letter-spacing: 0;
    margin-bottom: 9px;
`;

export const ErrorText = styled.p`
    color: #0b0b0b;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 20px;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
`;
