import { FC, useEffect, useState } from 'react';
import { RelewiseRelatedVehicles as RelewiseRelatedVehiclesType } from '../../../lib/api/models/umbraco/content-spot';
import { getSimilarProducts } from '../../../lib/api/search/search.api';
import { useSegmentContext } from '../../../hooks/use-segment-context';
import { ProductResult } from '@relewise/client';
import { mapRelewiseToProductCard } from '../../../lib/mappers/vehicle/product-card.mapper';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { VehicleProductListCard } from '../../vehicle/vehicle-product-list/vehicle-product-list-card';
import { StyledSpotWrapper } from './relewise-related-vehicles.styled';
import Link from 'next/link';

type IProps = {
    spot: RelewiseRelatedVehiclesType;
};

export const RelewiseRelatedVehicles: FC<IProps> = ({ spot }) => {
    const [relewiseProducts, setRelewiseProducts] = useState<ProductResult[]>([]);
    const segment = useSegmentContext();
    useEffect(() => {
        const getRelatedVehicles = async () => {
            const result = await getSimilarProducts(spot.entityId, segment, 'vehicle');
            if (result && result.recommendations) setRelewiseProducts(result.recommendations);
        };
        getRelatedVehicles();
    }, [segment, spot.entityId]);

    if (!relewiseProducts || relewiseProducts.length === 0) return null;

    return (
        <StyledSpotWrapper>
            <VehicleProductCardCarousel header={spot.header} text={spot.subText}>
                {relewiseProducts.map((product, index) => {
                    return (
                        <Link href={product.data?.Url.value} key={index}>
                            <a href={product.data?.Url.value} style={{ textDecoration: 'none' }}>
                                <VehicleProductListCard
                                    productCard={mapRelewiseToProductCard(product)}
                                    priceType="Purchase"
                                    className="keen-slider__slide"
                                    onCardClick={() => null}
                                />
                            </a>
                        </Link>
                    );
                })}
            </VehicleProductCardCarousel>
        </StyledSpotWrapper>
    );
};
