import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import {
    ContainerGrid,
    OwnershipInfoCardBenefits,
    StyledBookTrialButton,
    StyledCardFootNote,
    StyledCheckoutButton,
    StyledContactButton,
    StyledCtaWrapper,
    StyledDisclaimer,
    StyledHireDropdownWrapper,
    StyledLeasingActions,
    StyledLeasingDropdown,
    StyledPositionedDisclaimer,
    StyledPositionedPriceSection,
    StyledSellingPoint,
    StyledSellingPointList,
} from './ownership-card.styled';

import { sharedTypes } from '../../../lib/api/models/umbraco';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { next } from '../../../utils/helpers/incremental-integer.helper';
import { DropdownInputProps } from '../../forms/inputs';
import { OwnershipCardPrice, OwnershipCardPriceProps } from './ownership-card-price.component';

export type OwnershipInfoCardProps = {
    priceVisibilityChanged: (visible: boolean) => void;
    /**
     * Up to 5 selling points to be displayed in the card.
     */
    usps: Array<sharedTypes.LabelAndIcon>;
    price: Omit<OwnershipCardPriceProps, 'priceVisibilityChanged'>;
    disclaimer?: string | ReactNode;
    priceSection?: ReactNode;
    primaryCta: {
        label?: string;
        action?: () => void;
    };
    secondaryCta: {
        label?: string;
        action?: () => void;
    };
    linkCta?: {
        label?: string;
        action?: () => void;
    };
    footerCta: {
        label?: string;
        action?: () => void;
    };
    dropdownProps?: Pick<DropdownInputProps, 'label' | 'onChange' | 'options' | 'value'>;
    hireExtendMonthlyKmDropdownProps?: Pick<DropdownInputProps, 'label' | 'onChange' | 'options' | 'value'>;
    cardFootNote?: string;
};

export const OwnershipCard: FC<OwnershipInfoCardProps> = (props) => {
    const isMobile = useMediaQuery({ target: 'tablet' });
    const [dropdownDomId] = useState(`leasing-card-period-${next()}`);
    const [hireKmDropdownDomId] = useState(`leasing-card-km-monthly-${next()}`);

    const { isAFromPrice, selectedProduct } = ProductDetailsStore.useStoreState((state) => state);

    const uspsToShow = useMemo(() => {
        if (!selectedProduct) return [];
        return props.usps
            .filter((x) => {
                if (x.availability === null || x.availability === undefined || x.availability.length === 0) return true;
                if (x.availability === selectedProduct.availability) return true;
                if (props.price.isCompanyCar && x.availability === 'Company') return true;
                return false;
            })
            .splice(0, 5);
    }, [props.price.isCompanyCar, props.usps, selectedProduct]);

    const disclaimer = useMemo(
        () =>
            React.isValidElement(props.disclaimer) ? (
                <StyledPositionedDisclaimer>{props.disclaimer}</StyledPositionedDisclaimer>
            ) : (
                <StyledDisclaimer>{props.disclaimer}</StyledDisclaimer>
            ),
        [props.disclaimer]
    );

    const priceSection = useMemo(
        () => (props.priceSection ? <StyledPositionedPriceSection>{props.priceSection}</StyledPositionedPriceSection> : null),
        [props.priceSection]
    );

    const PricesUI = (
        <OwnershipCardPrice
            {...props.price}
            priceVisibilityChanged={(visible) => props?.priceVisibilityChanged(visible)}
            isAFromPrice={isAFromPrice}
            key={props.price.price}
        />
    );

    const SellingPointsUi = (
        <StyledSellingPointList key={'sellingPoints'} role="list">
            {uspsToShow.map((x, i) => (
                <StyledSellingPoint
                    key={i} // we use index here, because these never change and test data makes the correct scenario annoying
                    role="listitem"
                    iconUrl={x.icon?.src ? MEDIA_URL + x.icon.src : ''}
                    label={x.label}
                    isEmphasized={x.isEmphasized}
                    tooltip={x.tooltip}
                />
            ))}
        </StyledSellingPointList>
    );

    const LeasingPeriodDropdownUI =
        props.dropdownProps && props.hireExtendMonthlyKmDropdownProps ? (
            <StyledHireDropdownWrapper>
                <StyledLeasingDropdown canValidateInputField={false} id={dropdownDomId} isValid={false} key={'period'} {...props.dropdownProps} />
                <StyledLeasingDropdown
                    canValidateInputField={false}
                    id={hireKmDropdownDomId}
                    isValid={false}
                    key={'monthlyKm'}
                    {...props.hireExtendMonthlyKmDropdownProps}
                />
            </StyledHireDropdownWrapper>
        ) : null;

    const LinkCtaUI = props.linkCta?.action ? (
        <OwnershipInfoCardBenefits key={'benefits'} onClick={() => props.linkCta?.action?.() ?? null} variant="link">
            <p>{props.linkCta.label}</p>
        </OwnershipInfoCardBenefits>
    ) : null;

    const DisclaimerUI = disclaimer ? disclaimer : null;

    const PriceSectionUI = priceSection ? priceSection : null;

    const ActionsUI = (
        <StyledLeasingActions key={'actions'}>
            <StyledCtaWrapper>
                {props.secondaryCta.action ? (
                    <StyledBookTrialButton onClick={() => props.secondaryCta.action?.()}>
                        <span>{props.secondaryCta.label}</span>
                    </StyledBookTrialButton>
                ) : null}
                {props.primaryCta.action ? (
                    <StyledCheckoutButton onClick={() => props.primaryCta.action?.()}>
                        <span>{props.primaryCta.label}</span>
                    </StyledCheckoutButton>
                ) : null}
            </StyledCtaWrapper>
            {props.footerCta.action ? (
                <StyledContactButton onClick={() => props.footerCta.action?.()}>
                    <span>{props.footerCta.label}</span>
                </StyledContactButton>
            ) : null}
            {props.cardFootNote ? <StyledCardFootNote>{props.cardFootNote}</StyledCardFootNote> : null}
        </StyledLeasingActions>
    );

    // Order UI elements based on mobile or desktop
    const OrderedElements = isMobile
        ? [PricesUI, SellingPointsUi, LinkCtaUI, LeasingPeriodDropdownUI, PriceSectionUI, DisclaimerUI, ActionsUI]
        : [SellingPointsUi, LeasingPeriodDropdownUI, PricesUI, LinkCtaUI, PriceSectionUI, DisclaimerUI, ActionsUI];

    return <ContainerGrid>{OrderedElements}</ContainerGrid>;
};
