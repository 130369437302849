import styled from 'styled-components';
import { KeyValueTable } from '../../shared';

export const Description = styled.p`
    color: #0b0b0b;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 30px;
`;

export const SummarySection = styled.div``;

export const SummaryHeader = styled.h3`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const CalculationTable = styled(KeyValueTable)`
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
`;

export const ErrorText = styled.p`
    color: #0b0b0b;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 20px;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
`;
